import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSingleFrameSlider = styled.div`
  width: 100%;
  .frame-slider-wrap {
    overflow: hidden;
    &:after {
      content: '';
      height: ${rem(`72px`)};
      width: 100%;
      display: block;
    }
  }
  .frame-slider-wrap .slick-track {
    display: flex;
    align-items: center;
  }
  .frame-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .frame-slider-wrap .slick-dots {
    bottom: initial;
    top: 100%;
  }
  .frame-slider-wrap .slick-slider {
    padding-bottom: ${rem(`24px`)};
  }

  .frame__icon {
    position: absolute;
    z-index: 1099;
    top: 100%;
    width: ${rem(`72px`)};
    height: ${rem(`72px`)};
    color: ${({ theme }) => theme.colors.yellow_2};
    font-size: ${rem(`72px`)};
    line-height: ${rem(`72px`)};
    &[disabled],
    &:hover[disabled] {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.blue_3_45};
    }
    &--next {
      right: 0;
    }
    &--prev {
      left: 0;
    }
    ${({ theme }) => theme.breakpoints.desktop`
      transition: color 300ms ease-in-out;
      &:hover {
        color: ${theme.colors.pink_2};
        transition: color 300ms ease-in-out;
      }
    `}
  }
  .frame__dots {
    padding: 0 ${rem(`72px`)};
    li {
      width: ${rem(`36px`)};
      height: ${rem(`72px`)};
    }
    li button {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ theme }) => theme.breakpoints.tablet`
        &:hover:before {
          opacity: 1;
          color: ${theme.colors.purple_2};
          transition: opacity 300ms ease-in-out;
        }
      `}
    }
    li button:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: ${rem(`18px`)};
      opacity: 0.25;
      color: ${({ theme }) => theme.colors.purple_2};
      transition: opacity 300ms ease-in-out;
    }
    li.slick-active button:before {
      opacity: 1;
      color: ${({ theme }) => theme.colors.yellow_2};
    }
  }
  @keyframes frameZoomBlur {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes frameZoomBlurReverse {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

interface ISingleFrameSlider {
  slides: any;
  titles: string[];
}

interface IArrowButton {
  onClick?: any;
}

export const SingleFrameSlider = ({ slides, titles }: ISingleFrameSlider) => {
  const [nav, setNav] = useState(undefined);
  const [sliderRef, setSliderRef] = useState(undefined);
  const [slide, setSlide] = useState(0);

  function SampleNextArrow({ onClick }: IArrowButton) {
    return slide === slides.length - 1 ? (
      <button
        aria-label="Next"
        type="button"
        className="frame__icon frame__icon--next"
        onClick={() => {
          sliderRef.slickGoTo(0);
        }}
      >
        <FontAwesomeIcon icon={faArrowRotateLeft} />
      </button>
    ) : (
      <button
        aria-label="Next"
        type="button"
        className="frame__icon frame__icon--next"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faChevronCircleRight} />
      </button>
    );
  }

  function SamplePrevArrow({ onClick }: IArrowButton) {
    return (
      <button
        aria-label="Prev"
        type="button"
        className="frame__icon frame__icon--prev"
        onClick={onClick}
        disabled={slide === 0}
      >
        <FontAwesomeIcon icon={faChevronCircleLeft} />
      </button>
    );
  }

  const settingsThumbs = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: `.slider-for`,
    dots: true,
    fade: false,
    focusOnSelect: false,
    arrows: true,
    autoplay: false,
    speed: 300,
    cssEase: `ease-in-out`,
    pauseOnHover: true,
    swipeToSlide: true,
    centerPadding: `0px`,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots: any) => (
      <div>
        <Text type="body1" color="white_1">
          {titles[slide]} {slide + 1}/{slides.length}
        </Text>
        <ul className="frame__dots"> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      setSlide(currentSlide);
    },
  };

  useEffect(() => {
    setNav(sliderRef);
    return () => {
      setNav(undefined);
    };
  });

  return (
    <StyledSingleFrameSlider>
      <div className="frame-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav}
          ref={(sliderInstance) => setSliderRef(sliderInstance)}
        >
          {slides}
        </Slider>
      </div>
    </StyledSingleFrameSlider>
  );
};
