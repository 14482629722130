import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text/index';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledThumbnailSlider = styled.div`
  .thumbnail-slider-wrap {
    overflow: hidden;
    &:after {
      content: '';
      height: ${rem(`36px`)};
      width: 100%;
      display: block;
    }
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-dots {
    bottom: initial;
    top: 100%;
  }
  .thumbnail-slider-wrap .slick-slider {
    padding-bottom: ${rem(`24px`)};
  }
  .thumbnail-slider-wrap .slick-slider .slick-list {
    margin: 0 -${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      margin: 0 -${rem(`16px`)};
    `}
  }
  .thumbnail {
    position: relative;
    display: flex;
    padding: 0 ${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      padding: 0 ${rem(`16px`)};
    `}
    a {
      display: block;
      width: 100%;
      height: 100%;
      ${({ theme }) => theme.breakpoints.desktop`
        &:hover {
          .thumbnail__text {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
          }
          .thumbnail__image {
            img {
              animation: thumbnailZoomBlur 600ms forwards;
            }
          }
        }
      `}
    }
  }
  .thumbnail__text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: ${rem(`1px`)};
    left: ${rem(`7px`)};
    width: calc(100% - ${rem(`14px`)});
    height: ${rem(`36px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    z-index: 1099;
    border-bottom-right-radius: ${rem(`4px`)};
    border-bottom-left-radius: ${rem(`4px`)};
    transition: opacity 500ms ease-in-out;
    ${({ theme }) => theme.breakpoints.desktop`
      left: ${rem(`17px`)};
      width: calc(100% - ${rem(`34px`)});
      opacity: 0;
    `}
  }
  .thumbnail__image {
    background: ${({ theme }) => theme.colors.blue_1_30};
    width: 100%;
    height: 100%;
    border: ${rem(`1px`)} solid ${({ theme }) => theme.colors.pink_1};
    border-radius: ${rem(`6px`)};
    display: block;
    min-height: ${rem(`180px`)};
    img {
      animation: thumbnailZoomBlurReverse 1s forwards;
      object-fit: contain !important;
    }
    ${({ theme }) => theme.breakpoints.desktop`
      img {
        animation: thumbnailZoomBlurReverse 1s forwards;
        object-fit: cover !important;
      }
      &:hover {
        border: ${rem(`1px`)} solid ${theme.colors.yellow_1};
        transition: border 300ms ease-in-out;
      }
    `}
  }
  .thumbnail__icon {
    position: absolute;
    z-index: 1099;
    top: 100%;
    width: ${rem(`36px`)};
    height: ${rem(`36px`)};
    color: ${({ theme }) => theme.colors.pink_1};
    font-size: ${rem(`28px`)};
    line-height: ${rem(`36px`)};
    &--next {
      right: 0;
    }
    &--prev {
      left: 0;
    }
    ${({ theme }) => theme.breakpoints.desktop`
      transition: color 500ms ease-in-out;
      &:hover {
        color: ${theme.colors.yellow_1};
        transition: color 500ms ease-in-out;
      }
    `}
  }
  .thumbnail__dots {
    padding: 0 ${rem(`36px`)};
    li {
      width: ${rem(`36px`)};
      height: ${rem(`36px`)};
    }
    li button {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ theme }) => theme.breakpoints.tablet`
        &:hover:before {
          opacity: 1;
          color: ${theme.colors.yellow_1};
          transition: opacity 500ms ease-in-out;
        }
      `}
    }
    li button:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: ${rem(`13px`)};
      opacity: 0.25;
      color: ${({ theme }) => theme.colors.pink_1};
      transition: opacity 500ms ease-in-out;
    }
    li.slick-active button:before {
      opacity: 1;
      color: ${({ theme }) => theme.colors.pink_1};
    }
  }
  @keyframes thumbnailZoomBlur {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes thumbnailZoomBlurReverse {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

interface IThumbnailSlider {
  slides: any;
}

interface IArrowButton {
  onClick?: any;
}

function SampleNextArrow({ onClick }: IArrowButton) {
  return (
    <button
      aria-label="Next"
      type="button"
      className="thumbnail__icon thumbnail__icon--next"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronCircleRight} />
    </button>
  );
}

function SamplePrevArrow({ onClick }: IArrowButton) {
  return (
    <button
      aria-label="Prev"
      type="button"
      className="thumbnail__icon thumbnail__icon--prev"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronCircleLeft} />
    </button>
  );
}

export const ThumbnailSlider = ({ slides }: IThumbnailSlider) => {
  const [nav, setNav] = useState(undefined);
  const [sliderRef, setSliderRef] = useState(undefined);

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: false,
    asNavFor: `.slider-for`,
    dots: true,
    focusOnSelect: false,
    arrows: true,
    autoplay: false,
    speed: 600,
    autoplaySpeed: 2500,
    cssEase: `ease`,
    pauseOnHover: true,
    swipeToSlide: true,
    centerPadding: `0px`,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div>
        <ul className="thumbnail__dots"> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setNav(sliderRef);
    return () => {
      setNav(undefined);
    };
  });

  return (
    <StyledThumbnailSlider>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav}
          ref={(sliderInstance) => setSliderRef(sliderInstance)}
        >
          {slides.map(({ name, slug, thumbnailImage }) => {
            const { description: alt } = thumbnailImage;
            const image = getImage(thumbnailImage);
            return (
              <div className="thumbnail slick-slide" key={uuidv4()}>
                <Link to={`projects/${slug}`}>
                  <div className="thumbnail__text">
                    <Text noRhythem type="body1" tag="span" color="white_1">
                      {name}
                    </Text>
                  </div>
                  <GatsbyImage
                    image={image}
                    className="thumbnail__image slick-slide-image"
                    alt={alt}
                  />
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    </StyledThumbnailSlider>
  );
};
