import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { rem } from 'polished';
import {
  IServiceThumbnail,
  ServiceThumbnail,
} from '../ServiceThumbnail/ServiceThumbnail';

interface IStyledServiceThumbnailsGrid {}

const StyledServiceThumbnailsGrid = styled.div<IStyledServiceThumbnailsGrid>`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: ${rem(`16px`)};
  align-items: center;
  justify-content: space-evenly;
  grid-template-columns: repeat(2, minmax(${rem(`120px`)}, ${rem(`120px`)}));
  grid-template-rows: 1fr 1fr;
  ${({ theme }) => theme.breakpoints.phone`
    grid-template-columns: repeat(4,minmax(${rem(`72px`)}, ${rem(`96px`)}));
    grid-template-rows: 1fr;
  `}
  ${({ theme }) => theme.breakpoints.phablet`
    grid-gap: ${rem(`48px`)};
    grid-template-columns: repeat(4,minmax(${rem(`72px`)}, ${rem(`120px`)}));
    grid-template-rows: 1fr;
  `}
  ${({ theme }) => theme.breakpoints.tablet`
    grid-template-columns: repeat(4,minmax(${rem(`120px`)}, ${rem(`136px`)}));
    grid-template-rows: 1fr;
  `}
  ${({ theme }) => theme.breakpoints.desktop`
    grid-template-columns: repeat(4,minmax(${rem(`110px`)}, 1fr));
    grid-template-rows: 1fr;
  `}
`;

interface IServiceThumbnailsGrid {
  services: IServiceThumbnail[];
  className?: string;
}

export const ServiceThumbnailsGrid = ({
  services,
  className,
}: IServiceThumbnailsGrid) => {
  return (
    <StyledServiceThumbnailsGrid className={className}>
      {services.map(({ icon, name, slug }) => {
        return (
          <ServiceThumbnail
            key={uuidv4()}
            icon={icon}
            name={name}
            slug={slug}
          />
        );
      })}
    </StyledServiceThumbnailsGrid>
  );
};
