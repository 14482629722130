import React, { useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import { ServiceThumbnailsGrid } from '@/components/ServiceThumbnailsGrid';
import {
  faChevronRight,
  faBellConcierge,
  faChain,
  faInfo,
  faEnvelope,
  faRocket,
  // faBlog,
} from '@fortawesome/pro-light-svg-icons';
import { faVideoCamera } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@/components/Button';
import { SingleFrameSlider } from '@/components/SingleFrameSlider/SingleFrameSlider';
import { ProjectThumbnailsGrid } from '@/components/ProjectThumbnailsGrid';
import { ProjectThumbnail } from '@/components/ProjectThumbnail';
import { StayInTouch } from '@/components/StayInTouch';
import { Social } from '@/components/Social';
import { useActiveOnIntersect } from '@/hooks/useActiveOnIntersect';
import { EmployeeCard } from '@/components/EmployeeCard';
// import { RevealingSlider } from '@/components/RevealingSlider';
import { SectionHeader } from '@/components/SectionHeader';
import { Layout, Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { MdContent } from '../../components/MdContent';
import { HeaderWithMore } from '../../components/HeaderWithMore';
import { Seo } from '../../components/SEO';
import { ThumbnailSlider } from '../../components/ThumbnailSlider';
import { Banner } from '../../components/Banner';
import { CallToAction } from '../../components/CallToAction';
import { StyledHome } from './styles';
import { useStateValue } from '../../state';

interface IHomePageTemplate {
  content: any;
}

interface IHomePage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulIndex: any;
  };
  location: object;
}

const HomePageTemplate = ({ content }: IHomePageTemplate) => {
  const {
    title,
    welcomeNote,
    servicesTitle,
    servicesSubtitle,
    services,
    projectsTitle,
    projects,
    // articlesTitle,
    // articles,
    teamTitle,
    teamSubtitle,
    teamMembers,
    processesBannerData,
    ctaContactTitle,
    ctaTitle,
    ctaHookId,
    ctaIcon,
    ctaBody,
  } = content;
  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1" className="main-title">
          {title}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={welcomeNote} />
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="75%" className="last">
        <HeaderWithMore
          className="section-header section-header--extra"
          noMargin
          linkTo="services"
          linkText="Learn more"
        >
          <Text type="title3" tag="h3" color="gray_3">
            {servicesTitle}
          </Text>
          <Text
            type="subtitle2"
            tag="h5"
            color="white_1_45"
            className="section-subtitle"
          >
            {servicesSubtitle}
          </Text>
        </HeaderWithMore>
        <ServiceThumbnailsGrid services={services} />
      </Content>

      <Content restrictWidth setMaxWidth="75%">
        <Banner
          title={processesBannerData.title}
          content={processesBannerData.content}
        />
      </Content>

      <Content restrictWidth setMaxWidth="75%">
        <HeaderWithMore linkTo="projects" linkText="All projects">
          <Text type="title3" tag="h3" color="gray_3">
            {projectsTitle}
          </Text>
        </HeaderWithMore>
        <ThumbnailSlider slides={projects} />
      </Content>

      {/* <Content restrictWidth setMaxWidth="75%"> */}
      {/*   <HeaderWithMore linkTo="blog" linkText="All blog posts"> */}
      {/*     <Text type="title3" tag="h3" color="gray_3"> */}
      {/*       {articlesTitle} */}
      {/*     </Text> */}
      {/*   </HeaderWithMore> */}
      {/*   <RevealingSlider slides={articles} /> */}
      {/* </Content> */}

      <Content restrictWidth setMaxWidth="75%" className="last">
        <HeaderWithMore
          className="section-header"
          noMargin
          linkTo="about"
          linkText="About us"
        >
          <Text type="title3" tag="h3" color="gray_3">
            {teamTitle}
          </Text>
          <Text
            type="subtitle2"
            tag="h5"
            color="white_1_45"
            className="section-subtitle"
          >
            {teamSubtitle}
          </Text>
        </HeaderWithMore>
        <div className="team-members">
          {teamMembers.map(
            ({
              name,
              role,
              image: memberImage,
              githubPortfolio,
              linkedInPortfolio,
            }) => {
              const image = getImage(memberImage);
              return (
                <EmployeeCard
                  key={name}
                  name={name}
                  position={role}
                  className="team-member"
                  imageUrl={image}
                  alt={`Image of ${name}`}
                  githubLink={githubPortfolio}
                  linkedInLink={linkedInPortfolio}
                />
              );
            },
          )}
        </div>
      </Content>

      <Content shouldCenter>
        <Text
          type="subtitle2"
          tag="h5"
          color="white_1_45"
          className="section-subtitle"
        >
          {ctaContactTitle}
        </Text>
        <Link to="/contact">
          <Button size="large" disabled={false} iconLeft={faEnvelope}>
            Contact us
          </Button>
        </Link>
      </Content>

      <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      />
      <Social />
    </>
  );
};

const HomePageDesktopTemplate = ({ content }: IHomePageTemplate) => {
  const [, dispatch] = useStateValue();
  const {
    services,
    processes,
    projects,
    pages,
    teamTitle,
    teamSubtitle,
    teamMembers,
    // articles,
  } = content;
  const {
    name: servicesName,
    body: servicesBody,
    ctaBody: servicesCtaBody,
  } = pages.find(({ slug }) => slug === `services`);
  const { name: processesName, body: processesBody } = pages.find(
    ({ slug }) => slug === `processes`,
  );
  const { name: projectsName, body: projectsBody } = pages.find(
    ({ slug }) => slug === `projects`,
  );
  const {
    name: aboutName,
    body: aboutBody,
    subtitle: aboutSubtitle,
  } = pages.find(({ slug }) => slug === `about`);
  // const { name: blogName, body: blogBody } = pages.find(
  //   ({ slug }) => slug === `blog`,
  // );
  const {
    name: contactName,
    body: contactBody,
    subtitle: contactSubtitle,
  } = pages.find(({ slug }) => slug === `contact`);

  const servicesRef = useRef();
  const processesRef = useRef();
  const projectsRef = useRef();
  const aboutRef = useRef();
  const blogRef = useRef();
  const contactRef = useRef();

  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#services` },
      }),
    servicesRef,
  );
  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#processes` },
      }),
    processesRef,
  );
  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#projects` },
      }),
    projectsRef,
  );
  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#about` },
      }),
    aboutRef,
  );
  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#blog` },
      }),
    blogRef,
  );
  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#contact` },
      }),
    contactRef,
  );

  return (
    <>
      <section className="section" id="services">
        <SectionHeader
          icon={faBellConcierge}
          title={servicesName}
          subtitle={servicesBody?.body}
          ref={servicesRef}
        />
        <main className="main">
          <div className="services">
            {services.map(
              ({
                slug: serviceSlug,
                name: serviceName,
                excerpt: serviceExcerpt,
                icon: serviceIcon,
              }) => {
                const icon = getImage(serviceIcon?.gatsbyImageData);
                return (
                  <div className="service" key={serviceSlug}>
                    <div className="service__top">
                      <GatsbyImage
                        image={icon}
                        alt={`icon for ${serviceIcon?.description}`}
                        className="service-icon"
                      />
                      <Text
                        className="service-title"
                        type="title2"
                        tag="h4"
                        color="white_1"
                        noRhythem
                      >
                        {serviceName}
                      </Text>
                      <Text type="body1" tag="div" color="gray_3">
                        <MdContent content={serviceExcerpt?.excerpt} />
                      </Text>
                    </div>
                    <Link to={`/services/${serviceSlug}`}>
                      <Button
                        size="small"
                        disabled={false}
                        iconRight={faChevronRight}
                      >
                        Read more
                      </Button>
                    </Link>
                  </div>
                );
              },
            )}
          </div>
        </main>
      </section>

      <div className="banner rectangle">
        <Text className="banner__text" type="title2" tag="div" color="gray_3">
          <MdContent content={servicesCtaBody?.ctaBody} />
        </Text>
      </div>

      <section className="section" id="processes">
        <SectionHeader
          icon={faChain}
          title={processesName}
          subtitle={processesBody?.body}
          ref={processesRef}
        />
        <main className="main">
          <div className="processes">
            <div className="rectangle rectangle--pad-36">
              <SingleFrameSlider
                titles={processes
                  .sort((a: any, b: any) => a.order - b.order)
                  .map(({ name }) => name)}
                slides={processes
                  .sort((a: any, b: any) => a.order - b.order)
                  .map(({ body, image, name, subtitle, title, displayId }) => {
                    const parsedImage = getImage(image);
                    return (
                      <div key={displayId}>
                        <div className="process-slider">
                          <Content>
                            <Text type="title3" tag="h2" color="gray_3">
                              {title}
                            </Text>
                            <Text type="subtitle1" tag="h3" color="gray_3_65">
                              {subtitle}
                            </Text>
                            <Text type="body1" tag="div" color="gray_3">
                              <MdContent content={body?.body} />
                            </Text>
                          </Content>
                          <div className="process-slider__image">
                            <GatsbyImage
                              image={parsedImage}
                              className="slick-slide-image"
                              alt={`Image of ${name}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              />
            </div>
          </div>
        </main>
      </section>

      <section className="section" id="projects">
        <SectionHeader
          icon={faRocket}
          title={projectsName}
          subtitle={projectsBody?.body}
          ref={projectsRef}
        />
        <main className="main">
          <div className="projects">
            <ProjectThumbnailsGrid>
              {projects.map(
                ({
                  name,
                  slug,
                  thumbnailTitle,
                  thumbnailImage: nodeThumbnailImage,
                }) => {
                  const thumbnailImage = getImage(nodeThumbnailImage);
                  return (
                    <ProjectThumbnail
                      key={name}
                      title={thumbnailTitle}
                      image={thumbnailImage}
                      alt={nodeThumbnailImage.description}
                      linkTo={`/projects/${slug}`}
                    />
                  );
                },
              )}
            </ProjectThumbnailsGrid>
          </div>
        </main>
      </section>

      <section className="section" id="about">
        <SectionHeader
          icon={faInfo}
          title={aboutName}
          subtitle={aboutSubtitle}
          ref={aboutRef}
        />
        <main className="main">
          <div className="about three-by-two-fifth">
            <div className="about__members">
              <Text type="title3" tag="h3" color="gray_3">
                {teamTitle}
              </Text>
              <Text
                type="subtitle2"
                tag="h5"
                color="white_1_45"
                className="team-subtitle"
              >
                {teamSubtitle}
              </Text>
              <div className="team-members">
                {teamMembers.map(
                  ({
                    role,
                    name,
                    image: memberImage,
                    githubPortfolio,
                    linkedInPortfolio,
                  }) => {
                    const image = getImage(memberImage);
                    return (
                      <EmployeeCard
                        key={name}
                        name={name}
                        position={role}
                        className="team-member"
                        imageUrl={image}
                        alt={`Image of ${name}`}
                        githubLink={githubPortfolio}
                        linkedInLink={linkedInPortfolio}
                      />
                    );
                  },
                )}
              </div>
            </div>
            <div className="about__content">
              <Text
                type="body1"
                tag="div"
                color="gray_3"
                className="process-slider__content"
              >
                <MdContent content={aboutBody?.body} />
              </Text>
            </div>
          </div>
        </main>
      </section>

      {/* <section className="section" id="blog"> */}
      {/*   <SectionHeader */}
      {/*     icon={faBlog} */}
      {/*     title={blogName} */}
      {/*     subtitle={blogBody?.body} */}
      {/*     ref={blogRef} */}
      {/*   /> */}
      {/*   <main className="main"> */}
      {/*     <div className="blog"> */}
      {/*       <RevealingSlider slides={articles} /> */}
      {/*     </div> */}
      {/*   </main> */}
      {/* </section> */}

      <section className="section" id="contact">
        <SectionHeader
          icon={faEnvelope}
          title={contactName}
          subtitle={contactSubtitle}
          ref={contactRef}
        />
        <main className="main">
          <div className="contact side-by-side">
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={contactBody.body} />
            </Text>

            <StayInTouch id="contactPageContactForm2" />
          </div>
        </main>
      </section>
    </>
  );
};

const HomePage = ({ data, location }: IHomePage) => {
  const homePage = get(data, `contentfulIndex`);
  const {
    title,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    heroImage,
    svgTopPath,
    svgBottomPath,
    welcomeNote: { welcomeNote },
    servicesTitle,
    servicesSubtitle,
    services,
    processes,
    projectsTitle,
    projects,
    pages,
    articlesTitle,
    articles,
    teamTitle,
    teamSubtitle,
    teamMembers,
    processesBannerData,
    ctaContactTitle,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = homePage;
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <StyledHome>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            <Text type="title1" tag="h2" color="white_1">
              <span className="first-letter">{title[0]}</span>
              {title.slice(1)}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={welcomeNote} />
            </Text>
            {/* <Content enableFlex noMargin className="continue-reading"> */}
            {/*   <FontAwesomeIcon icon={faArrowCircleDown} size="3x" /> */}
            {/* </Content> */}
            <Link
              to="/presentation"
              state={{
                modal: true,
              }}
            >
              <Button size="large" disabled={false} iconLeft={faVideoCamera}>
                Play intro
              </Button>
            </Link>
          </Content>
        }
        desktopBody={
          <HomePageDesktopTemplate
            content={{
              servicesTitle,
              servicesSubtitle,
              services,
              processes,
              projectsTitle,
              projects,
              pages,
              articlesTitle,
              articles,
              teamTitle,
              teamSubtitle,
              teamMembers,
              processesBannerData,
            }}
          />
        }
        desktopCta={
          <Content>
            <CallToAction
              hookId={ctaHookId}
              icon={ctaIcon}
              title={ctaTitle}
              body={ctaBody.ctaBody}
              maxWidth="100%"
              className="cta"
            />
            <Social />
          </Content>
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={heroImage}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <HomePageTemplate
            content={{
              title,
              welcomeNote,
              servicesTitle,
              servicesSubtitle,
              services,
              projectsTitle,
              projects,
              articlesTitle,
              articles,
              teamTitle,
              teamSubtitle,
              teamMembers,
              processesBannerData,
              ctaContactTitle,
              ctaTitle,
              ctaHookId,
              ctaIcon,
              ctaBody,
            }}
          />
        </Main>
      </Layout>
    </StyledHome>
  );
};

export default HomePage;

export const HomePageQuery = graphql`
  query HomePage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulIndex(slug: { eq: $slug }) {
      templateKey
      title
      slug
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 100
          placeholder: BLURRED
        )
      }
      pages {
        slug
        title
        subtitle
        name
        body {
          body
        }
        ctaBody {
          ctaBody
        }
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      metaTitle
      metaDescription
      welcomeNote {
        welcomeNote
      }
      servicesTitle
      servicesSubtitle
      services {
        name
        slug
        excerpt {
          excerpt
        }
        icon {
          description
          svg {
            content
          }
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      projectsTitle
      projects {
        name
        slug
        thumbnailTitle
        thumbnailImage {
          description
          gatsbyImageData(
            width: 225
            height: 120
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      processes {
        body {
          body
        }
        title
        subtitle
        order
        name
        displayId
        image {
          gatsbyImageData(
            width: 372
            height: 279
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      articlesTitle
      articles {
        title
        slug
        id
        timeToRead
        tags
        createDate(formatString: "MMMM Do, YYYY")
        heroImage {
          description
          gatsbyImageData(width: 344, height: 180, quality: 100)
        }
        excerpt
        author {
          name
          slug
          image {
            gatsbyImageData(
              width: 40
              height: 40
              quality: 100
              placeholder: BLURRED
            )
          }
        }
      }
      teamTitle
      teamSubtitle
      teamMembers {
        image {
          gatsbyImageData(
            width: 72
            height: 72
            quality: 100
            placeholder: BLURRED
          )
        }
        name
        role
        slug
        githubPortfolio
        linkedInPortfolio
      }
      processesBannerData {
        title
        content
      }
      ctaContactTitle
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 100, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
  }
`;
