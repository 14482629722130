import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';

interface IStyledServiceThumbnail {}

const StyledServiceThumbnail = styled.div<IStyledServiceThumbnail>`
  ${({ theme }) => theme.breakpoints.desktop`
    &:hover {
      .service-thumbnail__title {
        color: ${theme.colors.yellow_1};
      }
    }
  `}
  .service-thumbnail {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: center;
  }
  .service-thumbnail__icon {
    width: 100%;
    height: 100%;
    max-width: ${rem(`180px`)};
    filter: drop-shadow(
      0px 0px ${rem(`20px`)} ${({ theme }) => theme.colors.blue_1}
    );
    transition: filter 500ms ease-in-out;
    animation: serviceThumbnailZoomBlurReverse 500ms forwards;
    &:hover {
      animation: serviceThumbnailZoomBlur 500ms forwards;
    }
  }
  .service-thumbnail__title {
    padding: ${rem(`12px`)} ${rem(`16px`)} 0;
    text-align: center;
    transition: color 300ms ease-in-out;
    height: 4.5rem;
  }

  @keyframes serviceThumbnailZoomBlur {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
      ${({ theme }) => `
          filter: drop-shadow(0px 0px ${rem(`4px`)} ${theme.colors.magenta_1})
        sepia(20%) contrast(110%);
      `}
    }
  }
  @keyframes serviceThumbnailZoomBlurReverse {
    0% {
      transform: scale(1.1);
      ${({ theme }) => `
          filter: drop-shadow(0px 0px ${rem(`4px`)} ${theme.colors.magenta_1})
        sepia(20%) contrast(110%);
      `}
    }
    100% {
      transform: scale(1);
    }
  }
`;

export interface IServiceThumbnail {
  icon: any;
  name: string;
  slug: string;
  className?: string;
}

export const ServiceThumbnail = ({
  icon,
  name,
  slug,
  className,
}: IServiceThumbnail) => {
  const { description: alt } = icon;
  const image = getImage(icon);
  return (
    <StyledServiceThumbnail className={className}>
      <Link className="service-thumbnail" to={`services/#${slug}`}>
        <GatsbyImage
          image={image}
          className="service-thumbnail__icon"
          alt={alt}
        />
        <Text
          noRhythem
          className="service-thumbnail__title"
          type="subtitle3"
          tag="h4"
          color="gray_3"
        >
          {name}
        </Text>
      </Link>
    </StyledServiceThumbnail>
  );
};
