import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons';
import { faSolarSystem } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';
import { Button } from '../Button';
import { MdContent } from '../MdContent';

interface IStyledBanner {
  fullHeight: boolean;
}

const StyledBanner = styled.div<IStyledBanner>`
  width: 100%;
  height: auto;
  max-height: ${rem(`216px`)};
  min-height: ${({ fullHeight }) => (fullHeight ? `100%` : `200px`)};
  position: relative;
  border: ${rem(`1px`)} solid ${({ theme }) => theme.colors.pink_2};
  border-radius: ${rem(`4px`)};
  overflow: hidden;
  .banner__content {
    max-width: calc(100% - ${rem(`32px`)});
    position: absolute;
    top: ${rem(`16px`)};
    left: ${rem(`16px`)};
    z-index: 1099;
    display: flex;
    flex-wrap: wrap;
    ${({ theme }) => theme.breakpoints.phone`
      max-width: 60%;
    `}
  }
  .banner__title {
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    padding: ${rem(`2px`)} ${rem(`6px`)} 0;
    border-radius: ${rem(`6px`)};
    margin-bottom: ${rem(`12px`)};
  }
  .banner__icon {
    font-size: ${rem(`28px`)};
    line-height: ${rem(`48px`)};
    margin-right: ${rem(`12px`)};
    display: inline;
    color: ${({ theme }) => theme.colors.pink_1};
  }
  .banner__body {
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    padding: ${rem(`4px`)} ${rem(`6px`)} ${rem(`12px`)};
    border-radius: ${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.phone`
      padding: ${rem(`4px`)} ${rem(`6px`)} ${rem(`8px`)};
    `}
    .subtitle1 {
      line-height: ${rem(`28px`)};
    }
  }
  .banner__link {
    position: absolute;
    bottom: ${rem(`16px`)};
    right: ${rem(`16px`)};
  }
  ${({ theme }) => theme.breakpoints.desktop`
    .banner__image {
      animation: bannerZoomBlur 1s forwards;
    }
    .banner__link:hover ~ .banner__image {
      animation: bannerZoomBlurReverse 1s forwards;
    }
  `}

  @media screen and (max-width: 576px) {
    .banner__image {
      width: ${rem(`576px`)};
      height: ${rem(`224px`)};
    }
  }
  @media screen and (min-width: 576px) and (max-width: 1366px) {
    .banner__image {
      width: 100%;
      height: ${rem(`200px`)};
    }
  }
  @media screen and (min-width: 1366px) {
    .banner__image {
      width: ${rem(`584px`)};
      height: ${rem(`320px`)};
    }
  }

  @keyframes bannerZoomBlur {
    0% {
      opacity: 0.7;
      transform: scale(1);
      filter: blur(3px);
    }
    100% {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  @keyframes bannerZoomBlurReverse {
    0% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 0.7;
      transform: scale(1);
      filter: blur(3px);
    }
  }
`;

interface IBanner {
  className?: string;
  title: string;
  content: string;
  fullHeight?: boolean;
}

export const Banner = ({
  title,
  content,
  className,
  fullHeight = false,
}: IBanner) => {
  const { bannerImageMobile, bannerImageTablet, bannerImageDesktop } =
    useStaticQuery(graphql`
      query BannerQuery {
        bannerImageDesktop: contentfulAsset(
          contentful_id: { eq: "7glTWYxavG6bKld9E7j39v" }
        ) {
          gatsbyImageData(
            layout: FULL_WIDTH
            cropFocus: CENTER
            quality: 85
            placeholder: BLURRED
          )
        }
        bannerImageTablet: contentfulAsset(
          contentful_id: { eq: "6iJI88cDuJcLTrfN4fhN4f" }
        ) {
          gatsbyImageData(
            layout: FULL_WIDTH
            cropFocus: CENTER
            quality: 85
            placeholder: BLURRED
          )
        }
        bannerImageMobile: contentfulAsset(
          contentful_id: { eq: "1oh3uuGIb9Es4YM0nu9ivR" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, quality: 85, placeholder: BLURRED)
        }
      }
    `);
  const desktopExtraImage = getImage(bannerImageDesktop);
  const desktopImage = getImage(bannerImageTablet);
  const mobileImage = getImage(bannerImageMobile);
  const { description: desktopAlt = `` } = bannerImageTablet;
  const sources = withArtDirection(desktopImage, [
    { media: `(min-width: 1366px)`, image: desktopExtraImage },
    { media: `(max-width: 1366px)`, image: desktopImage },
    { media: `(max-width: 576px)`, image: mobileImage },
  ]);
  return (
    <StyledBanner className={className} fullHeight={fullHeight}>
      <div className="banner__content">
        <div className="banner__title">
          <div className="banner__icon">
            <FontAwesomeIcon icon={faSolarSystem} />
          </div>
          <Text noRhythem type="title3" tag="span" color="yellow_1">
            {title}
          </Text>
        </div>
        <div className="banner__body">
          <Text type="body1" tag="h6" color="gray_3">
            <MdContent content={content} />
          </Text>
        </div>
      </div>
      <Link to="processes" className="banner__link">
        <Button size="small" iconRight={faChevronDoubleRight}>
          Show me
        </Button>
      </Link>
      <GatsbyImage className="banner__image" image={sources} alt={desktopAlt} />
    </StyledBanner>
  );
};
